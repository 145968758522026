import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "lib-ui-react";
import moment from "moment";

import Wrapper from "components/wrapper/wrapper";
import Onboarding from "components/onboarding/onboarding";
import RequestPassword from "pages/reset-password/request-reset-password";
import MultiplikInvite from "pages/invite/invite";
import MultiplikStatus from "pages/status/status";

import MultiplikService from "service/multiplik-service";
import SellerService from "service/seller-service";
import AuthenticationService from "service/authentication-service";
import SupersetService from "service/superset-service";

import routes from "navigation/routes";

import ProtectionIcon from "assets/images/protection-icon.png";
import OnboardingPopImage from "assets/images/onboarding-pop.png";

import { setEventOnDataLayer } from "utils/helpers/data-helper";

import styles from "components/ui/notification/styles.module.scss";
import requestPasswordStyle from "pages/reset-password/styles.module.scss";
import "pages/main/home.scss";
import FirstIndicationModal from "components/first-indication-modal/first-indication-modal";
import PushNotificationPixWeekModal from "components/ui/notification/push-notification-pix-week-modal";
import ImageHeaderPixWeek from "assets/images/pix_week.png";

function MultiplikHome() {
  const hideResetPasswordDialogReducer = useSelector(
    (state) => state?.multiplik?.hideResetPasswordDialog
  );
  const [activeTab, setActiveTab] = useState("invite");
  const [person, setPerson] = useState();
  const [loading, setLoading] = useState(true);

  const [showRequestPasswordDialog, setShowRequestPasswordDialog] =
    useState(false);
  const [seller, setSeller] = useState();
  const [showOnboarding, setShowOnboarding] = useState();
  const [showFirstIndication, setShowFirstIndication] = useState();
  const [passwordUpdated, setPasswordUpdated] = useState();
  const history = useHistory();
  const [performance, setPerformance] = useState(null);
  const [url, setUrl] = useState();
  const [whatsappLink, setWhatsappLink] = useState("");
  const [emailLink, setEmailLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [showOnboardingPopModal, setShowOnboardingPopModal] = useState(false);
  const [showPixWeekModal, setShowPixWeekModal] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(10, "years").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_SHOW_RESET_PASSWORD_MODAL",
      hideResetPasswordDialog: false,
    });

    if (hideResetPasswordDialogReducer && showRequestPasswordDialog) {
      setShowRequestPasswordDialog(false);
    }
  }, [hideResetPasswordDialogReducer]);

  useEffect(() => {
    const user = AuthenticationService.getUser();
    const userName = user?.aud;

    async function getPersonData(id) {
      const personData = await MultiplikService.findMemberData();

      SellerService.findSellerDetailsByProgram(id, "multiplik").then(
        ({ data }) => {
          const today = moment();
          const endBlackFridayDate = moment("2023-11-30 23:59:59.000");

          const hasFinishedOnboarding =
            data?.hasFinishedOnboarding != null
              ? JSON.parse(data?.hasFinishedOnboarding)
              : false;
          const hasViewedBlackFridayModal =
            data?.hasViewedBlackFridayModal != null
              ? JSON.parse(data?.hasViewedBlackFridayModal)
              : false;
          const passwordUpdated =
            data?.passwordUpdated != null
              ? JSON.parse(data?.passwordUpdated)
              : false;

          setPasswordUpdated(passwordUpdated);

          if (!hasFinishedOnboarding) {
            setShowOnboarding(true);
          } else if (
            endBlackFridayDate.isAfter(today) &&
            !hasViewedBlackFridayModal
          ) {
            setShowBlackFridayModal(true);
          } else if (!passwordUpdated) {
            setShowRequestPasswordDialog(true);
          }
        }
      );

      if (
        !personData.data.name ||
        !personData.data.contacts?.primaryEmailAddress?.address ||
        !personData.data.documents?.taxId?.number ||
        !personData.data.contacts?.primaryPhoneNumber?.number
      ) {
        routes.goToRoute(routes.MULTIPLIK_NEW);
      }

      dispatch({
        type: "SET_INFLUENCER_PERSON_DATA",
        influencerData: personData,
      });

      setPerson(personData);

      setLoading(false);
    }

    if (userName && person == null) {
      getPersonData(userName);
    } else {
      setLoading(false);
    }
  }, [person]);

  useEffect(() => {
    async function getSellerData() {
      const seller = await MultiplikService.findOrCreateSellerFromUser();

      setSeller(seller);

      dispatch({
        type: "SET_SELLER_DATA",
        seller,
      });

      sessionStorage.setItem(
        "isMultiplikOffline",
        seller?.data?.businessDetails?.partnerKind == 0 ? "true" : "false"
      );
    }

    if (!seller) {
      getSellerData();
    }

    if (seller && seller?.data?.businessDetails?.partnerKind == 0) {
      checkBusinessDetailsAndOpenOnboardingModal(seller?.data);
    }

    checkIfBankAccountHasBeenFilled();
  }, [seller]);

  useEffect(() => {
    if (!passwordUpdated) {
      addProgramURLParam();
    }
  }, [passwordUpdated]);

  useEffect(() => {
    async function fetchInvitationLink() {
      const invitationLink = await MultiplikService.getInvitationLink();
      setUrl(invitationLink?.data?.shortLink);

      return invitationLink?.data?.shortLink;
    }

    if (url === undefined) {
      fetchInvitationLink().then((url) => {
        let whatsappLinkRaw =
          "https://wa.me/?text=%281%29%20convite%20especial%21%0AVem%20comigo%20para%20SuperSim%20-%20empr%C3%A9stimo%20online.%20Receba%20agora%20Pix%20at%C3%A9%20R%242500%21%20Alta%20taxa%20de%20aprova%C3%A7%C3%A3o%2C%20inclusive%20para%20negativados.%20Experimente%3A%0A";
        let emailLinkRaw =
          "mailto:?subject=Link%20para%20pedir%20seu%20empr%C3%A9stimo&body=Est%C3%A1%20no%20pared%C3%A3o!%20Saia%20j%C3%A1%0A%0AContas%20atrasadas%2C%20boletos%20e%20outros%20perrengues%3F%20A%20SuperSim%20empr%C3%A9stimos%20online%20est%C3%A1%20ajudando%20muitas%20pessoas%20nesse%20momento.%20Evite%20golpes.%0A%20%20%E2%96%A0%20Receba%20at%C3%A9%20R%242500%20via%20Pix%20-%20para%20pagar%20em%20at%C3%A9%2012x%0A%20%20%E2%96%A0%20Aprova%C3%A7%C3%A3o%20r%C3%A1pida%20e%20segura%0A%20%20%E2%96%A0%20Para%20todos%2C%20inclusive%20negativados%0A%0AIndiquei%20um%20VALOR%20ESPECIAL%20para%20%40voc%C3%AA.%20Realmente%20espero%20que%20ajude.%0A%0AExperimente%20gr%C3%A1tis%3A%20";
        let facebookLinkRaw = "https://www.facebook.com/sharer/sharer.php?u=";

        const whatsappParams = new URLSearchParams();
        whatsappParams.set("utm_campaign", "whatsapp");
        let whatsappLinkUrl = `${whatsappLinkRaw}${encodeURIComponent(
          url
        )}?${whatsappParams}`;

        const emailParams = new URLSearchParams();
        emailParams.set("utm_campaign", "email");
        let emailLinkUrl = `${emailLinkRaw}${encodeURIComponent(
          url
        )}?${emailParams}`;

        const facebookParams = new URLSearchParams();
        facebookParams.set("utm_campaign", "facebook");
        let facebookLinkUrl = `${facebookLinkRaw}${encodeURIComponent(
          url
        )}?${facebookParams}`;

        setWhatsappLink(whatsappLinkUrl);
        setEmailLink(emailLinkUrl);
        setFacebookLink(facebookLinkUrl);
      });
    }
  }, [url]);

  useEffect(() => {
    getMultiplikPerformance();
  }, [dateFrom, dateTo]);

  const isWithinDates = showFirstIndication && person?.data?.createdAt;

  function checkBusinessDetailsAndOpenOnboardingModal(seller) {
    const {
      businessName,
      businessInvoicing,
      businessKind,
      corporateId,
      businessDetails,
    } = seller?.businessDetails;

    const keysToIgnore = ["complement"];

    const hasNullValueInAddress =
      businessDetails?.businessAddress &&
      Object.keys(businessDetails.businessAddress).some(
        (key) =>
          businessDetails.businessAddress[key] === null &&
          !keysToIgnore.includes(key)
      );

    if (
      !businessName ||
      businessInvoicing === null ||
      businessKind === null ||
      !corporateId ||
      hasNullValueInAddress
    ) {
      setShowOnboardingPopModal(true);
    }
  }

  const getMultiplikPerformance = async () => {
    let sellerPerformance = await MultiplikService.getPerformance(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    );
    setPerformance(sellerPerformance.data);

    const user = AuthenticationService.getUser();
    const userName = user?.aud;
    const invitations = sellerPerformance?.data?.invitations;
    const dateOfLastInvitation = moment(
      sellerPerformance?.data?.dateOfLastInvitation
    ).format("YYYY-MM-DD");
    const approvedInvitations = sellerPerformance?.data?.approvedInvitations;
    const availableReward = sellerPerformance?.data?.availableReward;
    const historyReward = sellerPerformance?.data?.historyReward;
    const daysWithoutInvitation = Math.abs(
      moment(dateOfLastInvitation).diff(moment(), "days")
    );

    const userCreatedAt = moment(person?.data?.createdAt);
    const startDate = moment("2023-11-01");
    const endDate = moment("2023-11-30").endOf("day");
    const isBetweeenDates = userCreatedAt?.isBetween(
      startDate,
      endDate,
      null,
      []
    );

    if (invitations > 0) {
      setEventOnDataLayer("active-influencer", {
        personId: userName,
        dateOfLastInvitation,
        invitations,
        approvedInvitations,
        availableReward,
        historyReward,
        daysWithoutInvitation,
      });
    } else if (isBetweeenDates) {
      setShowFirstIndication(true);
    } else {
      setEventOnDataLayer("inactive-influencer", {
        personId: userName,
      });
    }
  };

  function addProgramURLParam() {
    if (!window.location.href.includes("program=")) {
      let urlParams = window.location.search;

      const concatenator =
        urlParams !== undefined && urlParams !== null && urlParams !== ""
          ? "&"
          : "?";
      urlParams += `${concatenator}program=multiplik`;

      history.push(routes.MULTIPLIK_HOME + urlParams, history.location.state);
    }
  }

  const copyCodeFunction = async () => {
    if (url) {
      // Copy the url to clipboard.
      await navigator.clipboard.writeText(url);

      // Set copied to true.
      setIsCopied(true);

      // Wait 4 seconds and set copied back to false.
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    }
  };

  function goToPopOnboarding() {
    history.push(routes.MULTIPLIK_OFFLINE_POP_REGISTER);
    setShowOnboardingPopModal(false);
  }

  function closePixWeekModal() {
    if (person != null) {
      const personId = person?.data?.id;
      let details = {
        hasViewedBlackFridayModal: "true",
      };
      SellerService.setMultiplikSellerDetails(personId, details);
    }
    setShowPixWeekModal(false);
  }

  function checkIfBankAccountHasBeenFilled() {
    const businessBankAccount = seller?.data?.businessDetails?.bankAccount;
    const personBankAccount = person?.data.finance?.primaryBankAccount;
    const isPersonBankAccount =
      seller?.data?.businessDetails?.bankAccountType === 1;
    const isBusinessBankAccount =
      seller?.data?.businessDetails?.bankAccountType === 2;
    const corporateId = seller?.data?.businessDetails?.corporateId;

    if (person && seller) {
      if (
        (isBusinessBankAccount &&
          (!businessBankAccount?.bankCode ||
            !businessBankAccount?.branchNumber ||
            !businessBankAccount?.number ||
            businessBankAccount?.checkDigit == null ||
            !businessBankAccount?.kind ||
            !corporateId)) ||
        (isPersonBankAccount &&
          (!personBankAccount?.bankCode ||
            !personBankAccount?.branchNumber ||
            !personBankAccount?.number ||
            personBankAccount?.checkDigit == null ||
            !personBankAccount?.kind))
      ) {
        history.push(routes.MULTIPLIK_BANK);
      }
    }
  }

  return (
    <div className="logged-in-area multiplik-invite">
      {loading ? (
        <i className="fas fa-spinner fa-pulse fa-fw"></i>
      ) : (
        <Wrapper navTitle="Multiplik" goBackHidden={true} withNavbar={false}>
          <div className="multiplik-tabs-container">
            <div
              className={`multiplik-tab ${
                activeTab === "invite" ? "active" : null
              }`}
              onClick={() => setActiveTab("invite")}
            >
              Indicar
            </div>
            <div
              className={`multiplik-tab ${
                activeTab === "status" ? "active" : null
              }`}
              onClick={() => setActiveTab("status")}
            >
              Resultados
            </div>
          </div>
          {activeTab === "invite" ? (
            <MultiplikInvite
              url={url}
              whatsappLink={whatsappLink}
              emailLink={emailLink}
              facebookLink={facebookLink}
              person={person?.data}
              seller={seller?.data}
              performance={performance}
            />
          ) : (
            <MultiplikStatus
              performance={performance}
              seller={seller.data}
              setActiveTab={setActiveTab}
              url={url}
              copyCodeFunction={copyCodeFunction}
              isCopied={isCopied}
              dateFrom={dateFrom}
              dateTo={dateTo}
              setDateFrom={setDateFrom}
              setDateTo={setDateTo}
            />
          )}
        </Wrapper>
      )}

      {isWithinDates && (
        <Modal
          isShown={showFirstIndication}
          hide={() => setShowFirstIndication(false)}
        >
          <FirstIndicationModal
            setShowFirstIndication={setShowFirstIndication}
          />
        </Modal>
      )}
      <Modal isShown={showOnboarding} hide={() => setShowOnboarding(false)}>
        <Onboarding
          setShowOnboarding={setShowOnboarding}
          setShowRequestPasswordDialog={setShowRequestPasswordDialog}
          showPasswordDialog={true}
          passwordUpdated={passwordUpdated}
          person={person}
          isNotification={false}
        />
      </Modal>

      <Modal
        isShown={showRequestPasswordDialog}
        hide={() => setShowRequestPasswordDialog(false)}
      >
        {/* To do: create a header props in modal component */}
        <div className={requestPasswordStyle.content}>
          <div>
            <img
              className={requestPasswordStyle.headerImage}
              src={ProtectionIcon}
              alt="Imagem do cabeçalho"
            />
          </div>

          <h3 className={requestPasswordStyle.headerModalTitle}>
            RECUPERAÇÃO DE SENHA
          </h3>
        </div>
        <RequestPassword
          title={
            <>
              Para sua segurança, precisamos cadastrar uma nova senha. Insira
              seu <b>CPF</b> abaixo:
            </>
          }
        />
      </Modal>

      <Modal
        isShown={showOnboardingPopModal}
        hide={() => setShowOnboardingPopModal(false)}
      >
        <div className={styles.onboardingPopModal}>
          <div className={styles.onboardingPopModalHeader}>
            <img src={OnboardingPopImage} />
          </div>

          <div className={styles.onboardingPopModalHeaderContent}>
            <div className="text-left">
              <h4 className={styles.title}>
                Bem vindo(a)! Estamos abertos 24h pra você!
              </h4>
              <p className={styles.description}>
                Para finalizarmos seu cadastro, preencha os dados do seu ponto
                comercial.
              </p>
            </div>

            <Button
              className="ssui-plan-choice-button p1 full-width mt-2"
              label="Ok, entendi"
              size="small"
              onClick={goToPopOnboarding}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isShown={showPixWeekModal}
        hide={() => closePixWeekModal()}
        centered
      >
        <PushNotificationPixWeekModal
          setShowPixWeekModal={() => setShowPixWeekModal(false)}
          ImageHeaderPixWeek={ImageHeaderPixWeek}
          copyCodeFunction={copyCodeFunction}
        />
      </Modal>
    </div>
  );
}

// export { copyCodeFunction };
export default MultiplikHome;
