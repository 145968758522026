import React from "react";
import styles from "./styles.module.scss";

export default function MultiplikBar() {
  const isMultiplikOffline =
    window.location.pathname.includes("multiplik/offline");

  return (
    <div className={styles.backgroundMultiplikBar}>
      <div style={{ textAlign: "center" }}>
        <span className={styles.multiplikBarFirstText}>
          {isMultiplikOffline ? "MULTIPLIK! • Seja um parceiro | " : ""}{" "}
        </span>
        <span className={styles.multiplikBarSecondText}>
          {isMultiplikOffline
            ? "82% DE BUSCA NO SEU BAIRRO!!"
            : "(1) indicação = PIX DE R$50 "}{" "}
        </span>
      </div>
    </div>
  );
}
