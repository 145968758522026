import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Wrapper from "components/wrapper/wrapper";
import NotificationComponent from "components/ui/notification/notification-component";
import NotificationWrapperComponent from "../../components/ui/notification/notification-wrapper-component";
import Onboarding from "components/onboarding/onboarding";
import { Modal } from "lib-ui-react";
import HeroImage from "assets/images/imageHero.png";
import IconCheck from "assets/images/check-orange.png";
import styles from "./styles.module.scss";
import routes from "navigation/routes";

function MultiplikNotification() {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showUpdateCommissionModal, setShowUpdateCommissionModal] =
    useState(false);
  const history = useHistory();

  return (
    <Wrapper navTitle="Notificações" withNavbar>
      <div
        style={{
          width: "100%",
        }}
      ></div>
      <NotificationWrapperComponent
        onBoardingNotification={
          <NotificationComponent
            title={"Muito prazer, eu sou a Ana!"}
            description={"Ganhe dinheiro rápido"}
            openModal={() => setShowOnboardingModal(true)}
            date={""}
          />
        }
        updateCommissionNotification={
          <NotificationComponent
            title={"Atualização: modelo de comissão"}
            description={"Fique por dentro"}
            date="Março 2025"
            openModal={() => setShowUpdateCommissionModal(true)}
          />
        }
      />

      <Modal
        isShown={showUpdateCommissionModal}
        hide={() => setShowUpdateCommissionModal(false)}
        centered
      >
        <div className={styles.updateCommissionModal}>
          <img src={HeroImage} />

          <h2>
            Atualização:
            <br />
            modelo de comissão{" "}
          </h2>

          <div className={styles.item}>
            <img src={IconCheck} />
            <p>
              <span>A regra é clara:</span>
              se a pessoa que você indicou tiver o empréstimo emitido na
              SuperSim, você recebe <span>R$50</span> de recompensa.
            </p>
          </div>

          <div className={styles.divider} />

          <div className={styles.item}>
            <img src={IconCheck} />
            <p>
              Os pagamentos ocorrerão até o <span>dia 10</span> do mês seguinte.
            </p>
          </div>

          <button onClick={() => history.push(routes.MULTIPLIK_HOME)}>
            Compartilhe o link e ganhe
          </button>
        </div>
      </Modal>

      <Modal
        isShown={showOnboardingModal}
        hide={() => setShowOnboardingModal(false)}
        centered
      >
        <Onboarding
          setShowOnboarding={setShowOnboardingModal}
          showPasswordDialog={false}
          isNotification={true}
        />
      </Modal>
    </Wrapper>
  );
}

export default MultiplikNotification;
