import React, { useState } from "react";
import { Modal } from "lib-ui-react";

import InfluenceLevel from "components/influencer-level/influencer-level";
import {
  BalanceModal,
  InvitationsModal,
  ApprovedInvitationsModal,
} from "./modal-content";

import UtilHelper from "utils/helpers/util-helper";

import styles from "./styles.module.scss";

export default function Performance({ performance, seller }) {
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [showInvitationsModal, setShowInvitationsModal] = useState(false);
  const [showApprovedInvitationsModal, setShowApprovedInvitationsModal] =
    useState(false);

  return (
    <>
      <div className={styles.performance}>
        <div>
          <InfluenceLevel
            level={seller?.level}
            style={{ marginRight: "-13px" }}
          />
        </div>

        <div onClick={() => setShowInvitationsModal(true)}>
          <p>{performance?.invitations}</p>
          <span>Pedidos de empréstimo</span>
        </div>

        <div onClick={() => setShowApprovedInvitationsModal(true)}>
          <p>{performance?.approvedInvitations}</p>
          <span>Pedidos aceitos</span>
        </div>

        <div onClick={() => setShowBalanceModal(true)}>
          <p>
            {UtilHelper.formatNumberToCurrency(performance?.availableReward)}
          </p>
          <span>Saldo atual (recompensas)</span>
        </div>
      </div>

      <Modal isShown={showBalanceModal} hide={() => setShowBalanceModal(false)}>
        <BalanceModal performance={performance} />
      </Modal>

      <Modal
        isShown={showInvitationsModal}
        hide={() => setShowInvitationsModal(false)}
      >
        <InvitationsModal performance={performance} />
      </Modal>

      <Modal
        isShown={showApprovedInvitationsModal}
        hide={() => setShowApprovedInvitationsModal(false)}
      >
        <ApprovedInvitationsModal performance={performance} />
      </Modal>
    </>
  );
}
