import React from "react";
import MultiplikTerms from "assets/images/img_regulamento_multiplik.jpg";
import styles from "./styles.module.scss";

export default function TermsOfUse() {
  return (
    <div className={styles.container}>
      <img src={MultiplikTerms} alt="Termos de uso Multiplik" />

      <div>
        <p>
          <b>SOBRE O PROGRAMA:</b>
        </p>

        <p>
          A SuperSim ("empresa") é uma pessoa jurídica de direito privado que,
          por intermédio destes Termos, resolveu estabelecer um programa de
          recompensas por indicações ("campanha").
        </p>

        <p>
          O programa visa reforçar ainda mais a união entre a "empresa" e seus
          clientes e parceiros compensando-os pelas indicações que, normalmente,
          já são feitas de forma espontânea.
        </p>

        <p>
          Ao realizar o cadastro inicial, nos termos descritos abaixo, o Usuário
          ("participante") declara ter aceito de forma livre, expressa e
          inequívoca, os Termos e Condições Gerais do programa e com os quais
          concorda em seguir.
        </p>
      </div>

      <div>
        <p>
          <b>COMO FUNCIONA O PROGRAMA ("regras"):</b>
        </p>

        <p>
          Após o cadastro na "campanha", o "participante" poderá indicar seus
          amigos, familiares e/ou conhecidos para contratar a SuperSim, não
          havendo limite de indicações.
        </p>

        <p>
          Todas as pessoas que comprarem no site da SuperSim através do link de
          indicação do "participante" darão, ao mesmo, o direito a ganhar
          recompensas conforme as regras descritas abaixo.
        </p>

        <p>
          Caso o próprio "participante" utilize do seu link de indicação para
          comprar no site da SuperSim, esta compra não será contabilizada para
          fins de recompensa.
        </p>

        <p>
          A "campanha" contabiliza como indicação apenas uma indicação por
          cliente. Ou seja, o "participante" não poderá indicar a mesma pessoa
          mais de uma vez. Caso isso ocorra, apenas a primeira indicação será
          contabilizada para fins de recompensa.
        </p>
      </div>

      <div>
        <p>
          <b>RECOMPENSA :</b>
        </p>

        <p>
          Será remunerado o valor de R$50,00 por cada cliente emitido por meio
          do link de indicação do participante, disponibilizado na plataforma.
          Ressaltamos que o participante não poderá indicar a mesma pessoa mais
          de uma vez, tal indicação não será contabilizada.
        </p>

        <p>
          É importante salientar que fornecer informações incompletas,
          imprecisas ou falsas constitui violação dos deveres estabelecidos por
          estes Termos de Uso. O usuário é integralmente responsável por
          eventuais danos decorrentes de tal violação.
        </p>

        <p>
          Em casos nos quais irregularidades são identificadas de acordo com
          nossa política, não será efetuado o pagamento pelas propostas geradas
          e o indivíduo será removido da plataforma.
        </p>
      </div>

      <div>
        <p>
          <b>COMO RECEBO A MINHA RECOMPENSA:</b>
        </p>

        <p>
          As recompensas serão efetivadas, automaticamente, até o dia 10 do mês
          seguinte à aprovação do indicado. Não serão contabilizadas indicações
          canceladas.
        </p>

        <p>
          O pagamento das recompensas será enviado à conta bancária que o
          “participante” informar no momento do cadastro na SuperSim.
        </p>

        <p>
          Todas as informações serão automaticamente enviadas para o e-mail
          informado no momento de cadastro na "campanha" e também podem ser
          acompanhadas através da plataforma disponibilizada.
        </p>
      </div>

      <div>
        <p>
          <b>RESPONSABILIDADES:</b>
        </p>

        <p>
          A SuperSim não será responsável por ressarcir o “participante”, por
          quaisquer gastos com ligações telefônicas, pacotes de dados, SMS,
          mensagens, e-mails, correspondência ou qualquer outro valor despendido
          pelo "participante" em razão da sua participação nesta "campanha".
        </p>

        <p>
          A SuperSim não será responsável por pagar recompensas para indicações
          realizadas fora da "plataforma". É importante que o fluxo da compra
          pela pessoa indicada seja realizada em um único dispositivo (por
          exemplo: caso a compra seja iniciada pelo celular, deverá ser
          finalizada neste) para que a plataforma não perca o rastreio.
        </p>
      </div>

      <div>
        <p>
          <b>AUSÊNCIA DE VÍNCULO:</b>
        </p>

        <p>
          Ao fazer parte da "campanha", o "participante" declara (i) que aceita
          as orientações deste Termo; (ii) que sua adesão ocorre por sua livre e
          espontânea vontade; (iii) que esta "campanha" não cria qualquer
          vínculo com a SuperSim; (iv) que as indicações são realizadas de forma
          eventual e sem qualquer subordinação.
        </p>
      </div>

      <div>
        <p>
          <b>LGPD:</b>
        </p>

        <p>
          Ao aderir à "campanha", o "participante" autoriza expressamente a
          SuperSim a fazer uso dos dados inseridos na "plataforma", bem como
          autoriza a "plataforma" a compartilhar esses dados com outros agentes,
          caso seja necessário para finalidades exclusivas listadas neste Termo
          como por exemplo o pagamento das recompensas.
        </p>
      </div>

      <div>
        <p>
          <b>DISPOSIÇÕES GERAIS:</b>
        </p>

        <p>
          Apuração: Caso o participante não concorde com a apuração das
          indicações realizadas, será necessário que envie um e-mail para
          suporte.multiplik@supersim.com.br relatando os motivos que o levam a
          crer que houve eventual inconsistência na apuração das suas
          indicações.
        </p>

        <p>
          Interrupção, Suspensão ou Alteração: A Campanha poderá ser
          interrompida, suspensa ou alterada pela SuperSim, a seu exclusivo
          critério, sendo que os participantes serão comunicados por e-mail, bem
          como no painel do indicador.
        </p>

        <p>
          Casos não previstos: Casos omissos e/ou não previstos neste
          regulamento serão de boa-fé resolvidos pela comissão da SuperSim
          responsável pela "campanha". Dúvidas e esclarecimentos adicionais
          podem ser tratados pelo e-mail: suporte.multiplik@supersim.com.br
        </p>
      </div>

      <div>
        <h2>TERMOS E CONDIÇÕES DE USO</h2>

        <p>
          Você está interessado em contratar um empréstimo por meio da nossa
          plataforma (“Plataforma”) e, para prosseguirmos, é muito importante
          que você leia e concorde com estes Termos de Uso.
        </p>

        <p>
          A SuperSim é uma empresa de crédito digital que atua como
          correspondente bancário e tem autorização para oferecer produtos por
          meio de parcerias com bancos e financeiras.
        </p>

        <p>
          Estes Termos de Uso descrevem de maneira geral as condições aplicáveis
          a você durante o acesso e uso das funcionalidades da Plataforma,
          independentemente da forma como esta será acessada, por meio do site
          ou de qualquer aplicativo de dispositivo móvel da SuperSim.
        </p>

        <p>
          Os termos “você”, “usuário”, “seu” ou “sua” se referem a você usuário
          e os termos “SuperSim”, “nós” ou “nosso” se ferem à Supersim Análise
          de Dados e Correspondente Bancário Ltda., sociedade limitada, com sede
          na Cidade de São Paulo, Estado de São Paulo, na Avenida Nove de Julho,
          n°. 5955, loja A1 à lojaA15, CEP 01407-200, devidamente inscrita no
          Cadastro Nacional de Pessoas Jurídicas do Ministério da Economia
          (“CNPJ/ME”) sob o nº. 33.030.944/0001-60.
        </p>

        <p>
          A SuperSim tem o direito de atualizar estes Termos de Uso, a seu
          exclusivo critério, sempre que necessário. Mas fique tranquilo! A
          SuperSim o informará acerca de qualquer atualização relevante.
        </p>

        <p>
          Ao se inscrever ou usar, de qualquer forma, nossos serviços, ou ao
          navegar pelo nosso site, você estará concordando com estes Termos de
          Uso. Por favor, leia- os atentamente!
        </p>

        <p>
          Seu acordo conosco inclui esses Termos de Uso e nossa Política de
          Privacidade, que estarão sempre disponíveis para sua consulta por meio
          do nosso site: www.supersim.com.br.
        </p>

        <p>
          Caso você tenha qualquer dúvida, ficaremos felizes em ajudar por meio
          dos nossos canais de atendimento, que estão disponíveis no site:
          www.supersim.com.br.
        </p>
      </div>

      <div>
        <p>
          <b>1. PLATAFORMA SUPERSIM</b>
        </p>

        <p>
          1.1. A Plataforma pertence à SuperSim. Por meio da Plataforma
          SuperSim, facilitamos o seu acesso a produtos e serviços das
          instituições financeiras das quais somos parceiros.
        </p>

        <p>
          1.2. Somos um correspondente bancário da SOCINAL S.A. – CRÉDITO,
          FINANCIAMENTO E INVESTIMENTO, sociedade anônima, com sede na Cidade de
          Araruama, Estado do Rio de Janeiro, na Avenida Brasil, nº 10 – 4º
          andar, CEP 28970-000, inscrita no CNPJ/ME sob o nº.
          03.881.423/0001-56, instituição financeira devidamente autorizada pelo
          Banco Central do Brasil (“Banco Central” e “Instituição Financeira”,
          respectivamente).
        </p>

        <p>
          1.4. Como correspondente bancário, agimos sempre em nome da
          Instituição Financeira e sob sua orientação, nos termos da Resolução
          n°. 4.935,emitida pelo Conselho Monetário Nacional em 29 de julho de
          2021, conforme alterada.
        </p>
      </div>

      <div>
        <p>
          <b>2. CONFIDENCIALIDADE</b>
        </p>

        <p>
          2.1. Todas as suas informações são sigilosas e confidenciais. Para
          mais detalhes, acesse a nossa Política de Privacidade.
        </p>

        <p>
          2.2. Ao ler e concordar com a nossa Política de Privacidade, você
          autoriza a SuperSim a utilizar os seus dados, de acordo com as regras
          constantes destes Termos de Uso e da Política de Privacidade.
        </p>
      </div>

      <div>
        <p>
          <b>3. CADASTRO</b>
        </p>

        <p>
          3.1. Para utilizar as funcionalidades e ter acesso aos produtos
          oferecidos pela SuperSim, você deverá ter 18 (dezoito) anos ou mais e
          precisará realizar um cadastro na Plataforma, fornecendo todas as
          informações e/ou documentos solicitados.
        </p>

        <p>
          3.2. Ao realizar o cadastro, você declara que todos os dados
          fornecidos são verdadeiros, completos e precisos. Fornecer informações
          incompletas, imprecisas ou falsas constitui violação dos deveres
          destes Termos de Uso, sendo o usuário inteiramente responsável pelos
          danos a que tal violação der causa. Você deve também atualizar as
          informações cadastrais em caso de quaisquer alterações tão logo essas
          ocorram.
        </p>
      </div>

      <div>
        <p>
          <b>4. AUTORIZAÇÃO PARA CONSULTA AO SCR E BANCOS DE DADOS</b>
        </p>

        <p>
          4.1. Por meio da presente cláusula, você está ciente e concorda que
          além das consultas aqui mencionadas, a Instituição Financeira poderá
          consultar os débitos e responsabilidades decorrentes de operações com
          características de crédito, as informações e os registros de medidas
          judiciais que em seu nome e/ou razão social, conforme o caso, que
          constem ou venham a constar do Sistema de Informações de Crédito
          (“SCR”), gerido pelo Banco Central ou dos sistemas que venham a
          complementá-lo ou a substituí-lo, pois essa consulta é essencial para
          a análise e concessão do empréstimo solicitado. Além disso, você
          declara possuir todos os direitos e poderes de representação
          necessários para outorgar a presente autorização de consulta e
          registro em seu nome.
        </p>

        <p>4.1.1. Você declara estar ciente que:</p>

        <p>
          i – o SCR tem por finalidades fornecer informações ao Banco Central
          para fins de supervisão do risco de crédito a que estão expostas as
          instituições financeiras e propiciar o intercâmbio de informações
          entre essas instituições com o objetivo de subsidiar decisões de
          crédito e de negócios;
        </p>

        <p>
          ii – o SCR é alimentado mensalmente pela Instituição Financeira,
          mediante coleta de informações sobre operações concedidas com
          responsabilidade igual ou superior a R$ 200,00 (duzentos reais),
          vencidas e vincendas, bem como valores referentes às fianças e avais
          prestados pelas instituições financeiras a seus clientes;
        </p>

        <p>
          iii – poderei ter acesso aos dados constantes em meu nome no SCR por
          meio do Registrato – Extrato de Registro de Informações no Banco
          Central, um sistema que fornece gratuitamente informações disponíveis
          em cadastros administrados pelo Banco Central;
        </p>

        <p>
          iv – as informações remetidas para fins de registro no SCR são de
          exclusiva responsabilidade da Instituição Financeira, inclusive no que
          tange às inclusões, correções, exclusões, registro de medidas
          judiciais e manifestações de discordância quanto às tais informações
          constantes no sistema, sendo certo que somente a Instituição
          Financeira (responsável pela inclusão) poderá alterá-la ou excluí-la;
        </p>

        <p>
          v – pedidos de correções, exclusões e manifestações de discordância
          quanto às informações constantes do SCR deverão ser dirigidas à
          Instituição Financeira. Em caso de não entendimento entre as partes,
          poderá ser registrada reclamação na Central de Atendimento ao Público
          do Banco Central, ou por meio de medida judicial cabível, em face da
          Instituição Financeira responsável pelo lançamento de tais
          informações; e
        </p>

        <p>
          vi – mais informações sobre o SCR podem também ser obtidas em consulta
          ao ambiente virtual do Banco Central (www.bcb.gov.br).
        </p>
      </div>

      <div>
        <p>
          <b>5. MANDATO</b>
        </p>

        <p>
          5.1. O usuário confere, na forma do artigo 660 do Código Civil,
          mandato especial e específico à Supersim, para (i) emitir, em seu
          nome, cédula de crédito bancário representativa da operação de crédito
          prevista nestes Termos e Condições; e (ii) contratar, em seu nome,
          apólice de seguro prestamista relativa à operação de crédito prevista
          nestes Termos e Condições sempre que as condições comerciais de tal
          operação de crédito para o usuário com a contratação do seguro
          prestamista sejam mais benéficas ao usuário do que sem ela.
        </p>

        <p>
          5.1.1. A cláusula mandato é irrevogável e será válida até o completo
          cumprimento de todas as obrigações decorrentes do empréstimo.
        </p>
      </div>

      <div>
        <p>
          <b>6. MEDIDAS DE SEGURANÇA E SENHA</b>
        </p>

        <p>
          6.1. Você pode ficar tranquilo! A SuperSim oferece serviços de
          qualidade e adota as tecnologias necessárias para proteger suas
          informações. Porém, a sua segurança depende também de você. Para
          utilizar nossos serviços, você cria uma senha pessoal e sigilosa. Você
          não deve divulgar ou transferir essa senha para terceiros e você será
          o único responsável pelo uso não autorizado da senha em caso de
          divulgação ou transferência.
        </p>

        <p>
          6.2. Além disso, as transações realizadas em meio eletrônico podem ser
          vulneráveis à ação de terceiros. Por isso, utilize equipamentos com
          sistemas de segurança adequados e atualizados e não compartilhe o uso
          do seu dispositivo móvel com terceiros. Lembre-se, você será o único
          responsável pelo uso inadequado do meio eletrônico.
        </p>

        <p>
          6.3. Você concorda, ainda, em não utilizar o login e senha de outro
          usuário, a qualquer momento
        </p>
      </div>

      <div>
        <p>
          <b>7. RESPONSABILIDADES</b>
        </p>

        <p>7.1. Responsabilidades do usuário.</p>

        <p>7.1.1. O usuário se compromete a:</p>

        <p>
          i – Possuir acesso à Internet com os requisitos mínimos para acesso e
          uso da Plataforma.
        </p>

        <p>
          ii – Possuir seu próprio acesso a um endereço para envio de mensagens
          eletrônicas via Internet e pagar todas as taxas de serviço
          eventualmente cobradas por terceiros com relação a tal acesso.
        </p>

        <p>
          iii – Efetuar o seu cadastro na Plataforma, responsabilizando-se pela
          veracidade, completude e precisão das informações fornecidas.
        </p>

        <p>
          iv – Não divulgar ou transferir sua senha de acesso para terceiros,
          responsabilizando-se pelo uso não autorizado da senha em caso de
          divulgação ou transferência.
        </p>

        <p>
          v – Manter o ambiente de seu computador seguro, com uso de ferramentas
          disponíveis como antivírus e firewall, entre outras, atualizadas, de
          modo a contribuir na prevenção de riscos eletrônicos.
        </p>

        <p>
          vi – Isentar e indenizar tanto a SuperSim quanto seus diretores,
          agentes, sócios e funcionários indenes de qualquer prejuízo,
          responsabilidade, ação judicial ou demanda, devidos a ou decorrentes
          do uso da Plataforma ou de violação destes Termos de Uso ou da
          Política de Privacidade, assim como da utilização indevida das
          informações ou materiais da Plataforma.
        </p>

        <p>7.2 Responsabilidades da SuperSim.</p>

        <p>7.2.1. A SuperSim se compromete a:</p>

        <p>
          i – Envidar seus melhores esforços para manter as informações da
          Plataforma atualizadas, completas e precisas, sempre corrigindo
          informações imprecisas ou omissas.
        </p>

        <p>
          ii – Disponibilizar canais de atendimento no site:
          www.supersim.com.br.
        </p>

        <p>
          iii – Respeitar a privacidade de seus usuários, não editando ou
          divulgando informações privativas sem autorização prévia, exceto nos
          casos (a) expressamente previstos nos termos da Política de
          Privacidade. ou (b) frente a obrigação a fazê-lo mediante ordem
          judicial ou por força de Lei.
        </p>

        <p>
          7.2.2. A SuperSim pode disponibilizar na Plataforma links para sites
          terceiros. Esses sites são mantidos por terceiros sobre os quais a
          SuperSim não exerce qualquer tipo de controle ou ingerência. O uso
          desses sites e de suas funcionalidades, bem como a contratação de
          qualquer serviço ou produto neles ofertados, são de sua total
          responsabilidade e você deve se atentar aos termos de uso e política
          de privacidade de cada um desses sites. Em nenhuma hipótese a SuperSim
          será responsável por links acessados pelos usuários a partir da
          Plataforma.
        </p>
      </div>

      <div>
        <p>
          <b>8. VEDAÇÕES</b>
        </p>

        <p>
          8.1. É vedado ao usuário, sob pena de suspensão ou exclusão do
          programa:
        </p>

        <p>
          i – Violar qualquer legislação municipal, nacional ou internacional
          que seja integrada ao ordenamento jurídico brasileiro.
        </p>

        <p>
          ii – Assumir a personalidade ou identidade de outra pessoa, física ou
          jurídica.
        </p>

        <p>
          iii – Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
          outra forma tornar disponível qualquer conteúdo que seja ilegal,
          incluindo, mas sem se limitar a, conteúdo que seja ofensivo à honra e
          à privacidade de terceiros, difamatório ou calunioso, preconceituoso,
          racista, discriminatório, que faça apologia ao crime ou de qualquer
          forma censurável, ou que possa gerar qualquer responsabilidade civil
          ou criminal de acordo com a Lei.
        </p>

        <p>
          iv – Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
          forma tornar disponível qualquer conteúdo que viole quaisquer direitos
          de terceiro, incluindo direitos de propriedade intelectual.
        </p>

        <p>
          v – Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
          forma tornar disponível qualquer conteúdo que contenha vírus ou
          qualquer outro código, arquivo ou programa de computador com o
          propósito de interromper, destruir ou limitar a funcionalidade de
          qualquer software, hardware ou equipamento.
        </p>

        <p>vi – Ameaçar, coagir ou constranger demais usuários.</p>

        <p>vii – Violar direitos de sigilo e privacidade de terceiros.</p>

        <p>
          viii – Praticar quaisquer atos que, direta ou indiretamente, no todo
          ou em parte, possam causar prejuízo à SuperSim financeiros ou não, a
          outros usuários ou a qualquer terceiro.
        </p>
      </div>

      <div>
        <p>
          <b>9. SOLUÇÃO DE CONFLITOS</b>
        </p>

        <p>
          9.1. Caso você tenha algum problema relacionado a esses Termos de Uso
          ou à Política de Privacidade, ficaremos felizes em ajudar por meio dos
          nossos canais de atendimento. Esperamos sempre solucionar o seu
          problema da melhor forma possível.
        </p>

        <p>
          9.2. Porém, caso você entenda que sua reclamação não foi atendida de
          forma satisfatória nos nossos canais internos de atendimento, fica
          desde já estabelecido que estes Termos de Uso e a Política de
          Privacidade deverão ser interpretados de acordo com as leis da
          República Federativa do Brasil e o foro para resolução de eventuais
          conflitos será o do seu domicílio.
        </p>
      </div>

      <div>
        <p>
          <b>10. CAMPANHAS DE MARKETING</b>
        </p>

        <p>
          10.1 Para estar elegível a todas as campanhas de marketing, a
          solicitação de cada usuário precisa respeitar as seguintes regras:
        </p>

        <p>
          i – O usuário receber a aprovação final da equipe SuperSim e o e- mail
          formalizando a aprovação.
        </p>

        <p>
          ii – O preenchimento do formulário com informações verdadeiras. O
          preenchimento incorreto invalida a candidatura.
        </p>

        <p>
          iii – A aprovação final da equipe SuperSim e o e-mail formalizando a
          aprovação precisam ocorrer necessariamente antes das 15h de dias úteis
          do calendário nacional, caso contrário o pagamento será feito somente
          no próximo dia útil.
        </p>

        <p>
          iv – Um modelo de aparelho celular compatível poderá ser necessário
          para a aprovação do crédito.
        </p>

        <p>
          v - Após a aprovação final como descrito no item (iii), a
          transferência do recurso será respeitando o expediente bancário, dias
          úteis das 10h às 16h. Caso o cliente seja aprovado fora desse horário,
          a transferência do recurso será agendada, prevalecendo as regras
          descritas neste item.
        </p>
      </div>

      <div>
        <p>
          <b>
            Para saber mais sobre termo de privacidade dos empréstimos online
            confira:{" "}
            <a
              href="https://www.supersim.com.br/termos/privacidade/?originalReferer=www.supersim.com.br&originalSource=www.supersim.com.br"
              target="_blank"
              rel="noreferrer"
            >
              Termo de privacidade
            </a>
          </b>
        </p>
      </div>
    </div>
  );
}
