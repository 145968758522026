import React from "react";
import Wrapper from "components/wrapper/wrapper";
import { Faq, FaqQuestion } from "lib-ui-react";
import styles from "./styles.module.scss";
import MoneyIcon from "assets/images/money-icon.png";

function MultiplikFaq() {
  return (
    <Wrapper navTitle="Dúvidas frequentes" withNavbar>
      <div
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "auto",
          marginBottom: "10em",
          textAlign: "left",
        }}
      >
        <Faq container style="secondary">
          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Quem pode participar e como se cadastrar no Multiplik?
              </div>
            }
          >
            <p>
              O Multiplik é o programa de indicação da SuperSim. Qualquer pessoa
              física pode participar e indicar (sem limite de indicações) o
              empréstimo pessoal online e receber recompensas! Não está
              participando? Cadastre-se{" "}
              <a
                href="https://vendedor.supersim.com.br/multiplik/entrar"
                target="_blank"
                rel="noreferrer"
              >
                aqui
              </a>
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Como funciona o programa de indicação?
              </div>
            }
          >
            <ol>
              <li>Cadastre-se no programa Multiplik</li>
              <li>Acesse seu link único de indicação pessoal</li>
              <li>
                Compartilhe quantas vezes desejar para todos os grupos de amigos
                e em redes sociais
              </li>
              <li>
                Receba recompensa por indicações que o empréstimo for emitido.
              </li>
              <li>
                Acompanhe seus resultados através do Painel de Indicações.
                Acesse{" "}
                <a
                  href="https://vendedor.supersim.com.br/multiplik/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  aqui
                </a>
              </li>
            </ol>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                O que preciso fazer para gerar mais renda extra com o Multiplik?
              </div>
            }
          >
            <p>
              Basta ter mais de 18 (dezoito) anos e criar uma conta no
              Multiplik. Depois, compartilhe o seu link único de indicação com
              todo mundo!
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Veja qual status está sua indicação (pago, validado, pendente,
                cancelado ou reprovado)
              </div>
            }
          >
            <p>
              Acesse o ambiente Multiplik > aba “Resultados” > Minhas indicações
            </p>

            <ol>
              <li>Clique no ícone de calendário</li>
              <li>Selecione o período desejado</li>
              <li>
                Será exibida a lista de indicações correspondente ao período que
                você selecionou
              </li>
            </ol>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Quando vou receber o dinheiro com o Indique & Ganhe?
              </div>
            }
          >
            <p>
              As remunerações serão pagas todo mês, até o dia 10 do mês seguinte
              à aprovação.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Como sei que tenho direito a ganhar uma ou mais recompensas?
              </div>
            }
          >
            <p>
              As informações são claras e transparentes! Basta entrar na sua
              conta e verificar o seu painel de Indicação. Lá, você vai poder
              acompanhar o status de cada indicado e ficar por dentro de todos
              os detalhes dos pagamentos. Tudo pensado para deixar você no
              controle e com um sorriso no rosto a cada conquista!
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Fiz uma indicação, mas não recebi o valor referente a ela. O que
                devo fazer?
              </div>
            }
          >
            <p>
              Basta entrar na sua conta, clicar no painel de Indicações e
              verificar se a sua indicação já está apta para a recompensa.
              Lembre-se que os pagamentos são feitos todo dia 10 (referente ao
              mês anterior). Se estiver tudo certo e já tiver passado o período
              de pagamento, fique tranquilo: nós fazemos uma revisão cuidadosa
              dos pagamentos anteriores e o valor vai estar a caminho no próximo
              pagamento.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Posso usar meu link de indicação para solicitar um empréstimo?
              </div>
            }
          >
            <p>
              Sim, mas essa solicitação em particular não vai se qualificar para
              o pagamento das indicações aprovadas. Mas não se preocupe, o
              programa de ndicação está pronto para te recompensar sempre que
              você indicar outras pessoas que sejam elegíveis e novas para a
              SuperSim. Então, é só continuar espalhando as boas notícias e as
              recompensas vão seguir chegando!
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Qual valor da recompensa e quando recebo o pagamento das
                indicações?
              </div>
            }
          >
            <p>
              A recompensa por indicação com empréstimo emitido é de R$50,00. O
              pagamento ocorre mensalmente e o prazo máximo de recebimento é até
              o dia 10 do mês seguinte.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Atualização da conta bancária
              </div>
            }
          >
            <p>Veja como é simples atualizar os seus dados bancários:</p>
            <p>
              Acesse o Ambiente Multiplik > Menu > Clique em Configurações >
              Edite os campos bancários > Clique em “Atualizar dados”.
            </p>
            <p>
              Para o depósito das suas indicações, aceitamos os bancos:
              Santander, Bradesco, Banco do Brasil, Itaú, Caixa Econômica
              Federal, Iti, Next, Nubank, Banco Inter e Banrisul.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Como indicar
              </div>
            }
          >
            <p>
              É muito fácil começar a indicar e a fazer renda extra sem sair de
              casa! Após realizar a sua inscrição, copie seu link de indicação e
              compartilhe!
            </p>
            <p>
              <i>Importante:</i> Recomendamos você não usar encurtadores de
              links externos para que não haja perda de dados durante o
              processo.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Status dos indicados
              </div>
            }
          >
            <p>
              Quer conferir suas indicações de uma forma fácil e rápida? Aqui
              estão os passos:
            </p>
            <p>
              Acesse o Multiplik > Aba Resultados > Minhas indicações > Clique
              no ícone de CALENDÁRIO (Período) > Escolha o período desejado e
              pronto! A lista de suas indicações para esse período será exibida.
            </p>
          </FaqQuestion>

          <FaqQuestion
            toggleIconStyle="arrow"
            toggleIconColor="orange"
            title={
              <div
                style={{
                  padding: "0px",
                  display: "flex",
                  fontSize: "16px",
                  color: "#4d4f49",
                }}
              >
                Outras dúvidas?
              </div>
            }
          >
            <p style={{ fontSize: "15px" }}>
              Entre em contato com nosso time de atendimento através do e-mail:{" "}
              <br />
              <a href="mailto: suporte.multiplik@supersim.com.br">
                suporte.multiplik@supersim.com.br
              </a>
            </p>
          </FaqQuestion>
        </Faq>
      </div>
    </Wrapper>
  );
}

export default MultiplikFaq;
